import React from 'react';

import {
    Button, PricingCardsWrapper, PricingCard, ActionsWrapper, Checkbox,
    FontAwesomeIcon, IconsSolid, ReactAux, Alert, InfoCard,
} from '@jkhy/vsg-design-system';

const allPricingCards = [
    {
        title: '1 year line of credit',
        amount: '$ 73,950',
        conditionsData: [
            { title: 'Monthly Payment', amount: 'Payment Amount Varies', description: ['Principal & Interest'] },
            { title: 'Interest Rate', amount: '4 %', description: ['Variable Rate: 4%; 10% (Ceiling); 3% Floor'] },
            { title: 'APR', amount: '4.75 %' },
        ],
        feesData: [{ title: 'Application Fee', amount: '$250' }, { title: 'Annual Renewal Fee', amount: '$100' },]
    },
    {
        title: '3 Year term',
        amount: '$ 73,950',
        conditionsData: [
            { title: 'Monthly Payment', amount: '$2,209', description: ['Principal & Interest'] },
            { title: 'Interest Rate', amount: '5 %', description: ['Fixed Rate'] },
            { title: 'APR', amount: '5.25 %' },
        ],
        feesData: [{ title: 'Application Fee', amount: '$250' }, { title: 'Annual Renewal Fee', amount: '$100' },]
    },
    {
        title: '5 Year term',
        amount: '$ 73,950',
        conditionsData: [
            { title: 'Monthly Payment', amount: '$1,409', description: ['Principal & Interest'] },
            { title: 'Interest Rate', amount: '5.5 %', description: ['Fixed Rate'] },
            { title: 'APR', amount: '5.95 %' },
        ],
        feesData: [{ title: 'Application Fee', amount: '$250' }, { title: 'Annual Renewal Fee', amount: '$100' },]
    },
];

class PageResults extends React.Component<any, { selectedID: number }> {

    state = {
        selectedID: 1
    };

    render() {
        const { selectedID } = this.state;

        return (
            <ReactAux>
                <div className="text-center mb-3">
                    <FontAwesomeIcon className="text-success mb-2" icon={IconsSolid.faThumbsUp} size="l" />
                    <h1 className="mb-05">Congratulations!</h1>
                    <h3 className="mb-1">You have been approved for a loan.</h3>
                    <h5 className="text-muted">Please select one of the options below:</h5>
                </div>

                <PricingCardsWrapper>
                    {
                        allPricingCards.map((card, i) => {
                            const index = i;

                            return (
                                <ReactAux key={index}>
                                    <PricingCard
                                        className="mb-2"
                                        selected={selectedID === index}
                                        {...(index === 1 && {
                                            tooltipText: "This loan option matches the terms requested.",
                                            badge: {
                                                icon: IconsSolid.faClipboardCheck,
                                                title: 'Terms Requested'
                                            }
                                        })}
                                        title={card.title}
                                        amount={card.amount}
                                        conditionsData={card.conditionsData}
                                        feesData={card.feesData}
                                        onSelect={() => this.setState({ selectedID: index })}
                                    />
                                </ReactAux>
                            )
                        })
                    }
                </PricingCardsWrapper>

                <Checkbox className="mb-1 mt-3" htmlFor="accept" id="accept" name="accept">I accept the loan terms. Repayment Terms: 60 Monthly principal and interest payment of $1.942.</Checkbox>
                <Checkbox className="mb-3" htmlFor="accept2" id="accept2" name="accept2">I’m interested in learning more about Credit Life Insurance.</Checkbox>
                <p className="mb-3">
                    Loan amounts approved may differ from amount requested based on information provided. The application fee will be deducted from the loan proceeds. Annual renewal fees will be deducted from your Business checking account after 12 months and annually thereafter.You have 15 days to select a loan option from above. After 15 days, the application process will need to be restarted.lf you have any questions, please contact the Ovation Financial Contact Center at 555-555-5555.
                </p>
                <InfoCard className="mb-3">
                    <Alert className="mb-2" type="info" text="Click the Withdrawn button if you with to withdraw your application." />
                    <Button className="mb-2" btnType="secondary w-md-auto w-sm-100">withdrawn</Button>
                </InfoCard>
                <Alert className="mb-3" type="success" text={`SELECTED: ${allPricingCards[selectedID].title} - ${allPricingCards[selectedID].amount}.`} />

                <ActionsWrapper>
                    <Button iconRight={IconsSolid.faChevronRight} link={{ path: '/views/pages/13' }}>Submit</Button>
                </ActionsWrapper>
            </ReactAux>
        )
    }
}

export default PageResults;